<template>
  <page-main
    :title="
      `We're going to cover your roles and responsibilities at ${companyName}`
    "
    :aside="['Some Titles are self-explanatory, like Chef or Software Engineer. Others need more explanation: What did you do as an Operations Associate? The Role field helps you expand on that.',
    'If you enter a role, keep it succinct.']"
    nextText="On to your accomplishments!"
    :buttonTabIndex="`${tiPosOffset + 4}`"
    eventName="nav"
    @nav="nav('/WorkAccomplishments/' + key)"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-dual-list
            label="Positions you held:"
            labelOne="Title"
            labelTwo="Role"
            buttonLabel="Add Position"
            v-model="positions"
            tabindex="2"
            autofocus
            dataNameOne="title"
            dataNameTwo="role"
            keyName="key"
          ></r-dual-list>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-textarea
            v-model="description"
            header="Work Responsibilities"
            subText="Briefly (1-2 sentences), what were your basic tasks and responsibilities while you were at this company?"
            :tabindex="`${tiPosOffset + 1}`"
          ></r-textarea>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-switch
            v-model="hasDRs"
            label="Did you have any direct reports?"
            :tabindex="`${tiPosOffset + 2}`"
          ></r-switch>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-text-field
            v-model="directReports"
            label="How many direct reports?"
            :disabled="!hasDRs"
            type="number"
            :tabindex="`${tiPosOffset + 3}`"
          ></r-text-field>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import { mapFields } from "vuex-map-fields";
import { mapGetters, mapMutations, mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "WorkRoles",
  data: () => ({
    hasDRs: false
  }),
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.key != pageKey) {
      if (!this.doesKeyExist(pageKey)) {
        this.createEntry({ key: pageKey });
      }
      this.key = pageKey;
    }

    if (this.positions.length == 0) {
      this.positions.push({ title: "", role: "", key: uuid.gen() });
    }

    this.hasDRs = this.directReports > 0;
  },
  methods: {
    save() {
      if (!this.hasDRs) {
        this.directReports = 0;
      }

      this.saveWork();
    },
    ...mapMutations("work", ["createEntry"]),
    ...mapActions("work", ["saveWork"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  },
  components: {},
  computed: {
    ...mapGetters("education", ["doesKeyExist"]),
    ...mapFields("work", {
      key: "key",
      companyName: "workGeneral.companyName",
      positions: "workRole.positions",
      description: "workResponsibilities.description",
      directReports: "workResponsibilities.directReports"
    }),
    displayDR: function() {
      return this.hasDRs ? "Yes" : "No";
    },
    tiPosOffset() {
      return this.positions.length * 3 + 1; // last tab index in the positions section
    }
  }
};
</script>
